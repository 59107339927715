import { useState } from 'react';
import {
  IconCameraX,
  IconCameraPlus,
  IconCameraFilled,
} from '@tabler/icons-react';
import { Dropzone } from '@mantine/dropzone';
import { useHover, useMergedRef } from '@mantine/hooks';
import { Text, Flex, Avatar, Divider, Overlay } from '@mantine/core';

import classes from './StaffImageUploader.module.scss';

const MAX_FILE_SIZE_IN_MB = 2;

const StaffImageUploader: React.FC = () => {
  const { hovered, ref } = useHover();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleDrop = (files: File[]) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageUrl(e.target?.result as string);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Flex align={{ md: 'unset', base: 'center' }} gap={20} direction="column">
      <Dropzone
        pos="relative"
        radius="circle"
        onDrop={handleDrop}
        ref={useMergedRef(ref)}
        className={classes.dropzone}
        accept={['image/png', 'image/jpeg']}
        maxSize={MAX_FILE_SIZE_IN_MB * 1024 ** 2}
      >
        <Avatar
          size={160}
          src={imageUrl}
          radius="circle"
          className={imageUrl ? null : classes.avatar}
        >
          <Flex gap={10} align="center" direction="column">
            <Dropzone.Accept>
              <IconCameraPlus size={30} color="var(--mantine-color-blue-6)" />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconCameraX size={30} color="var(--mantine-color-red-6)" />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconCameraFilled size={30} color="var(--mantine-color-gray-5)" />
            </Dropzone.Idle>
            <Text size="xs">Upload photo</Text>
          </Flex>
        </Avatar>
        {hovered && imageUrl && (
          <Overlay radius="circle" backgroundOpacity={0.6}>
            <Flex gap={10} align="center" justify="center" h="100%">
              <IconCameraPlus size={30} color="var(--mantine-color-gray-5)" />
            </Flex>
          </Overlay>
        )}
      </Dropzone>
      <Flex
        align="center"
        gap={{ md: 15, base: 5 }}
        direction={{ md: 'row', base: 'column' }}
      >
        <Flex gap={5}>
          <Text span fz="sm" c="dimmed">
            Allowed format:
          </Text>
          <Text span fz="sm">
            JPG, JPEG, and PNG
          </Text>
        </Flex>
        <Divider
          size={1}
          c="dimmed"
          orientation="vertical"
          display={{ md: 'block', base: 'none' }}
        />
        <Flex gap={5}>
          <Text span fz="sm" c="dimmed">
            Max file size:
          </Text>
          <Text span fz="sm">
            {MAX_FILE_SIZE_IN_MB}MB
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StaffImageUploader;
