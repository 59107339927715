// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfile_user__hp\\+XL {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-sm);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  border-radius: var(--mantine-radius-md);
  transition: background-color 100ms ease;
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));
}
@media (min-width: 48em) {
  .UserProfile_user__hp\\+XL {
    width: auto;
  }
}

.UserProfile_userActive__oUUM1 {
  background-color: var(--mantine-color-gray-1);
}

.UserProfile_menuItem__9wyMf {
  padding: calc(var(--mantine-spacing-sm) / 1.5) var(--mantine-spacing-sm);
}
.UserProfile_menuItem__9wyMf:hover {
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/layouts/appLayout/header/UserProfile.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,kCAAA;EACA,0EAAA;EACA,uCAAA;EACA,uCAAA;EACA,sFAAA;AACF;AAIE;EAZF;IAaI,WAAA;EADF;AACF;;AAIA;EACE,6CAAA;AADF;;AAIA;EACE,wEAAA;AADF;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".user {\n  display: block;\n  width: 100%;\n  padding: var(--mantine-spacing-sm);\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n  border-radius: var(--mantine-radius-md);\n  transition: background-color 100ms ease;\n  background-color: light-dark(\n    var(--mantine-color-gray-0),\n    var(--mantine-color-dark-8)\n  );\n\n  @media (min-width: 48em) {\n    width: auto;\n  }\n}\n\n.userActive {\n  background-color: var(--mantine-color-gray-1);\n}\n\n.menuItem {\n  padding: calc(var(--mantine-spacing-sm) / 1.5) var(--mantine-spacing-sm);\n\n  &:hover {\n    border-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `UserProfile_user__hp+XL`,
	"userActive": `UserProfile_userActive__oUUM1`,
	"menuItem": `UserProfile_menuItem__9wyMf`
};
export default ___CSS_LOADER_EXPORT___;
