// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_list__SjoFI {
  gap: 2rem;
  flex-wrap: nowrap;
  position: relative;
  overflow-y: scroll;
  margin-bottom: calc(var(--mantine-spacing-lg) + 0.5rem) !important;
}

.Tabs_indicator__NwZoM {
  background-color: var(--mantine-color-white);
  border-bottom: 1px solid var(--mantine-color-dark-4);
}

.Tabs_tab__l2nrf {
  z-index: 1;
  font-weight: 600;
  padding: 0.65rem 0;
  transition: color 100ms ease;
  color: var(--mantine-color-gray-7);
}
.Tabs_tab__l2nrf[data-active] {
  color: var(--mantine-color-black);
}

.Tabs_panel__i-zsn {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 10rem);
}
@media screen and (min-width: 62em) {
  .Tabs_panel__i-zsn {
    overflow-y: unset;
    overflow-x: unset;
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Tabs.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kEAAA;AACF;;AAEA;EACE,4CAAA;EACA,oDAAA;AACF;;AAMA;EACE,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,4BAAA;EACA,kCAAA;AAHF;AAKE;EACE,iCAAA;AAHJ;;AAeA;EACE,kBAAA;EACA,kBAAA;EACA,2BAAA;AAZF;AAcE;EALF;IAMI,iBAAA;IACA,iBAAA;IACA,YAAA;EAXF;AACF","sourcesContent":[".list {\n  gap: 2rem;\n  flex-wrap: nowrap;\n  position: relative;\n  overflow-y: scroll;\n  margin-bottom: calc(var(--mantine-spacing-lg) + 0.5rem) !important;\n}\n\n.indicator {\n  background-color: var(--mantine-color-white);\n  border-bottom: 1px solid var(--mantine-color-dark-4);\n\n  @mixin dark {\n    background-color: var(--mantine-color-dark-6);\n  }\n}\n\n.tab {\n  z-index: 1;\n  font-weight: 600;\n  padding: 0.65rem 0;\n  transition: color 100ms ease;\n  color: var(--mantine-color-gray-7);\n\n  &[data-active] {\n    color: var(--mantine-color-black);\n  }\n\n  @mixin dark {\n    color: var(--mantine-color-dark-1);\n\n    &[data-active] {\n      color: var(--mantine-color-white);\n    }\n  }\n}\n\n.panel {\n  overflow-y: scroll;\n  overflow-x: hidden;\n  height: calc(100vh - 10rem);\n\n  @media screen and (min-width: 62em) {\n    overflow-y: unset;\n    overflow-x: unset;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `Tabs_list__SjoFI`,
	"indicator": `Tabs_indicator__NwZoM`,
	"tab": `Tabs_tab__l2nrf`,
	"panel": `Tabs_panel__i-zsn`
};
export default ___CSS_LOADER_EXPORT___;
