// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound_root__zPyKV {
  padding-top: 80px;
  padding-bottom: 80px;
}

.notFound_title__WRaPO {
  text-align: center;
  font-weight: 900;
  font-size: 4rem;
  line-height: 1;
  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
  color: var(--mantine-color-gray-2);
}
@media (max-width: 48em) {
  .notFound_title__WRaPO {
    font-size: 32px;
  }
}

.notFound_description__ds3pR {
  max-width: 500px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}`, "",{"version":3,"sources":["webpack://./src/pages/notFound/index.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,oDAAA;EACA,kCAAA;AACF;AACE;EARF;IASI,eAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,YAAA;EACA,qCAAA;EACA,oDAAA;AAEF","sourcesContent":[".root {\n  padding-top: 80px;\n  padding-bottom: 80px;\n}\n\n.title {\n  text-align: center;\n  font-weight: 900;\n  font-size: 4rem;\n  line-height: 1;\n  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));\n  color: var(--mantine-color-gray-2);\n\n  @media (max-width: 48em) {\n    font-size: 32px;\n  }\n}\n\n.description {\n  max-width: 500px;\n  margin: auto;\n  margin-top: var(--mantine-spacing-xl);\n  margin-bottom: calc(1.5 * var(--mantine-spacing-xl));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `notFound_root__zPyKV`,
	"title": `notFound_title__WRaPO`,
	"description": `notFound_description__ds3pR`
};
export default ___CSS_LOADER_EXPORT___;
