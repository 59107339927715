// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserButton_user__fbBAz {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-md);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
}
.UserButton_user__fbBAz:hover {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));
}

.UserButton_userButton__Zvos7 {
  padding: 0.2rem 0;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layouts/appLayout/sideNav/UserButton.module.scss"],"names":[],"mappings":"AASA;EACE,cAAA;EACA,WAAA;EACA,kCAAA;EACA,0EAAA;AARF;AAJE;EACE,sFAAA;AAMJ;;AAUA;EACE,iBAAA;EACA,eAAA;AAPF","sourcesContent":["@mixin hover {\n  &:hover {\n    background-color: light-dark(\n      var(--mantine-color-gray-0),\n      var(--mantine-color-dark-8)\n    );\n  }\n}\n\n.user {\n  display: block;\n  width: 100%;\n  padding: var(--mantine-spacing-md);\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n\n  @include hover;\n}\n\n.userButton {\n  padding: 0.2rem 0;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": `UserButton_user__fbBAz`,
	"userButton": `UserButton_userButton__Zvos7`
};
export default ___CSS_LOADER_EXPORT___;
