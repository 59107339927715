// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardList_progressBar__7upiq {
  width: 100%;
}
@media (min-width: 75em) {
  .CardList_progressBar__7upiq {
    width: 300px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/attendant/trainingData/CardList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EAHF;IAII,YAAA;EAEF;AACF","sourcesContent":[".progressBar {\n  width: 100%;\n\n  @media (min-width: 75em) {\n    width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `CardList_progressBar__7upiq`
};
export default ___CSS_LOADER_EXPORT___;
