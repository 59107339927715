// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appLayout_appShell__9O8t1 {
  --sidenav-cut: 2.5rem;
  --main-cut: 0.25rem;
}

.appLayout_header__Sn2YF {
  border-bottom: none;
  background-color: #fcfcfc;
  inset-inline-start: calc(var(--app-shell-navbar-offset, 0rem) + var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut));
}

.appLayout_main__lr6ZG {
  background-color: #fcfcfc;
  height: 100vh;
  padding-top: calc(var(--app-shell-header-offset, 0rem) + 15px);
}
@media (min-width: 48em) {
  .appLayout_main__lr6ZG {
    padding-inline-start: calc(var(--app-shell-navbar-offset, 0rem) + var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut));
    padding-inline-end: calc(var(--app-shell-aside-offset, 0rem) + var(--app-shell-padding) - var(--main-cut));
  }
}

.appLayout_navbar__hD-G\\+ {
  width: calc(var(--app-shell-navbar-width) - var(--sidenav-cut));
}`, "",{"version":3,"sources":["webpack://./src/layouts/appLayout/index.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,gIAAA;AACF;;AAKA;EACE,yBAAA;EACA,aAAA;EACA,8DAAA;AAFF;AAIE;EALF;IAMI,kIAAA;IAIA,0GAAA;EAJF;AACF;;AAUA;EACE,+DAAA;AAPF","sourcesContent":[".appShell {\n  --sidenav-cut: 2.5rem;\n  --main-cut: 0.25rem;\n}\n\n.header {\n  border-bottom: none;\n  background-color: #fcfcfc;\n  inset-inline-start: calc(\n    var(--app-shell-navbar-offset, 0rem) +\n      var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut)\n  );\n}\n\n.main {\n  background-color: #fcfcfc;\n  height: 100vh;\n  padding-top: calc(var(--app-shell-header-offset, 0rem) + 15px);\n\n  @media (min-width: 48em) {\n    padding-inline-start: calc(\n      var(--app-shell-navbar-offset, 0rem) +\n        var(--app-shell-padding) - var(--sidenav-cut) - var(--main-cut)\n    );\n    padding-inline-end: calc(\n      var(--app-shell-aside-offset, 0rem) +\n        var(--app-shell-padding) - var(--main-cut)\n    );\n  }\n}\n\n.navbar {\n  width: calc(var(--app-shell-navbar-width) - var(--sidenav-cut));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appShell": `appLayout_appShell__9O8t1`,
	"header": `appLayout_header__Sn2YF`,
	"main": `appLayout_main__lr6ZG`,
	"navbar": `appLayout_navbar__hD-G+`
};
export default ___CSS_LOADER_EXPORT___;
