// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaffListItem_item__eB7-R {
  padding: var(--mantine-spacing-sm) 0;
}
@media (min-width: 48em) {
  .StaffListItem_item__eB7-R {
    min-width: 19rem;
    max-width: 100%;
    border-block: 1px solid transparent;
  }
}
.StaffListItem_item__eB7-R.StaffListItem_itemActive__hR0gl {
  background-color: rgb(245, 246, 247, var(--tw-bg-opacity, 1));
  border-block: 1px solid var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/attendant/staffDirectory/StaffListItem.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AACE;EAHF;IAII,gBAAA;IACA,eAAA;IACA,mCAAA;EAEF;AACF;AAAE;EACE,6DAAA;EACA,2CAAA;AAEJ","sourcesContent":[".item {\n  padding: var(--mantine-spacing-sm) 0;\n\n  @media (min-width: 48em) {\n    min-width: 19rem;\n    max-width: 100%;\n    border-block: 1px solid transparent;\n  }\n\n  &.itemActive {\n    background-color: rgb(245 246 247 / var(--tw-bg-opacity, 1));\n    border-block: 1px solid var(--border-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `StaffListItem_item__eB7-R`,
	"itemActive": `StaffListItem_itemActive__hR0gl`
};
export default ___CSS_LOADER_EXPORT___;
