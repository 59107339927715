// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_headerContainer__u2i54 {
  flex: 1 1;
  height: fit-content;
}

.header_link__eapfT {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);
}
@media (max-width: 48em) {
  .header_link__eapfT {
    height: 42px;
    width: 100%;
  }
}

.header_logo__SX1LH {
  display: flex;
}
.header_logo__SX1LH svg {
  max-width: 100px;
}

.header_subLink__mtoZ5 {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
}

.header_dropdownFooter__wxzww {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}

.header_logo__SX1LH img {
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/appLayout/header/index.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,uCAAA;EACA,wCAAA;EACA,qBAAA;EACA,yEAAA;EACA,gBAAA;EACA,sCAAA;AACF;AACE;EAXF;IAYI,YAAA;IACA,WAAA;EAEF;AACF;;AAQA;EACE,aAAA;AALF;AAOE;EACE,gBAAA;AALJ;;AASA;EACE,WAAA;EACA,4DAAA;EACA,uCAAA;AANF;;AAgBA;EACE,sFAAA;EAIA,4CAAA;EACA,qCAAA;EACA,sEAAA;EACA,yCAAA;EACA,0FAAA;AAhBF;;AAoBA;EACE,WAAA;AAjBF","sourcesContent":[".headerContainer {\n  flex: 1;\n  height: fit-content;\n}\n\n.link {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding-left: var(--mantine-spacing-md);\n  padding-right: var(--mantine-spacing-md);\n  text-decoration: none;\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));\n  font-weight: 500;\n  font-size: var(--mantine-font-size-sm);\n\n  @media (max-width: 48em) {\n    height: 42px;\n    width: 100%;\n  }\n\n  @mixin hover {\n    background-color: light-dark(\n      var(--mantine-color-gray-0),\n      var(--mantine-color-dark-6)\n    );\n  }\n}\n\n.logo {\n  display: flex;\n\n  svg {\n    max-width: 100px;\n  }\n}\n\n.subLink {\n  width: 100%;\n  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n  border-radius: var(--mantine-radius-md);\n\n  @mixin hover {\n    background-color: light-dark(\n      var(--mantine-color-gray-0),\n      var(--mantine-color-dark-7)\n    );\n  }\n}\n\n.dropdownFooter {\n  background-color: light-dark(\n    var(--mantine-color-gray-0),\n    var(--mantine-color-dark-7)\n  );\n  margin: calc(var(--mantine-spacing-md) * -1);\n  margin-top: var(--mantine-spacing-sm);\n  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);\n  padding-bottom: var(--mantine-spacing-xl);\n  border-top: 1px solid\n    light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));\n}\n\n.logo img {\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `header_headerContainer__u2i54`,
	"link": `header_link__eapfT`,
	"logo": `header_logo__SX1LH`,
	"subLink": `header_subLink__mtoZ5`,
	"dropdownFooter": `header_dropdownFooter__wxzww`
};
export default ___CSS_LOADER_EXPORT___;
