// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaffImageUploader_dropzone__xzROn {
  width: 160px;
  height: 160px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.StaffImageUploader_avatar__H2zpN {
  padding: 10px;
  border: 1px dashed var(--mantine-color-gray-2);
}`, "",{"version":3,"sources":["webpack://./src/pages/attendant/staffDirectory/StaffImageUploader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8CAAA;AACF","sourcesContent":[".dropzone {\n  width: 160px;\n  height: 160px;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n\n.avatar {\n  padding: 10px;\n  border: 1px dashed var(--mantine-color-gray-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": `StaffImageUploader_dropzone__xzROn`,
	"avatar": `StaffImageUploader_avatar__H2zpN`
};
export default ___CSS_LOADER_EXPORT___;
