// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 62em) {
  .ScrollSection_scrollSection__hoaB5 {
    overflow-y: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollSection.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAA;EACF;AACF","sourcesContent":["@media screen and (min-width: 62em) {\n  .scrollSection {\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollSection": `ScrollSection_scrollSection__hoaB5`
};
export default ___CSS_LOADER_EXPORT___;
