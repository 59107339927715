// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VoiceProfile_voiceOption__RGzyX {
  padding: 0;
}
.VoiceProfile_voiceOption__RGzyX:where([data-combobox-disabled]) {
  cursor: pointer;
  opacity: 0.7;
}
.VoiceProfile_voiceOption__RGzyX:hover {
  background-color: var(--mantine-color-gray-1);
}

.VoiceProfile_voiceOptionItem__\\+6n2j.VoiceProfile_voiceOptionItemClicked__OqY8L {
  background-color: var(--mantine-color-gray-1);
}`, "",{"version":3,"sources":["webpack://./src/pages/attendant/attendantSettings/VoiceProfile.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AAEE;EACE,6CAAA;AAAJ;;AAKE;EACE,6CAAA;AAFJ","sourcesContent":[".voiceOption {\n  padding: 0;\n\n  &:where([data-combobox-disabled]) {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n\n  &:hover {\n    background-color: var(--mantine-color-gray-1);\n  }\n}\n\n.voiceOptionItem {\n  &.voiceOptionItemClicked {\n    background-color: var(--mantine-color-gray-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"voiceOption": `VoiceProfile_voiceOption__RGzyX`,
	"voiceOptionItem": `VoiceProfile_voiceOptionItem__+6n2j`,
	"voiceOptionItemClicked": `VoiceProfile_voiceOptionItemClicked__OqY8L`
};
export default ___CSS_LOADER_EXPORT___;
