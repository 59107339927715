// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pricing_leftPlan__qWQxP {
  border-radius: 0.75rem;
  border: 1px solid var(--input-border-color);
  padding: 1.5rem 1.25rem;
}

.Pricing_rightPlan__nL2PO {
  border-radius: 0.75rem;
  border: 1px solid var(--input-border-color);
  padding: 1.5rem 1.25rem;
  background-color: #fcfcfc;
}

@media (min-width: 75em) {
  .Pricing_leftPlan__qWQxP {
    border-right: none;
    padding: 2rem 2.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .Pricing_rightPlan__nL2PO {
    padding: 2rem 2.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/upgrade/Pricing.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,2CAAA;EACA,uBAAA;AACF;;AAEA;EACE,sBAAA;EACA,2CAAA;EACA,uBAAA;EACA,yBAAA;AACF;;AAEA;EACE;IACE,kBAAA;IACA,oBAAA;IACA,0BAAA;IACA,6BAAA;EACF;EAEA;IACE,oBAAA;IACA,yBAAA;IACA,4BAAA;EAAF;AACF","sourcesContent":[".leftPlan {\n  border-radius: 0.75rem;\n  border: 1px solid var(--input-border-color);\n  padding: 1.5rem 1.25rem;\n}\n\n.rightPlan {\n  border-radius: 0.75rem;\n  border: 1px solid var(--input-border-color);\n  padding: 1.5rem 1.25rem;\n  background-color: #fcfcfc;\n}\n\n@media (min-width: 75em) {\n  .leftPlan {\n    border-right: none;\n    padding: 2rem 2.5rem;\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n  }\n\n  .rightPlan {\n    padding: 2rem 2.5rem;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftPlan": `Pricing_leftPlan__qWQxP`,
	"rightPlan": `Pricing_rightPlan__nL2PO`
};
export default ___CSS_LOADER_EXPORT___;
