// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideNav_navbarContainer__dsmKp {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.sideNav_header__YrMUD {
  padding: var(--mantine-spacing-md);
  max-height: 60px;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.sideNav_logo__m\\+l8O {
  display: flex;
  padding-top: 0.5rem;
}
.sideNav_logo__m\\+l8O svg {
  max-width: 80px;
}

.sideNav_links__uT56x {
  flex: 1 1;
  padding-top: 1rem;
}

.sideNav_linksInner__bRuse {
  padding: var(--mantine-spacing-xs) 0;
}

.sideNav_footer__1vJbM {
  display: flex;
  gap: 1rem;
  padding: var(--mantine-spacing-md);
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/layouts/appLayout/sideNav/index.module.scss"],"names":[],"mappings":"AAAA;EACE,qFAAA;EAIA,aAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAKA;EACE,kCAAA;EACA,gBAAA;EACA,yEAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;AAIE;EACE,eAAA;AAFJ;;AAMA;EACE,SAAA;EACA,iBAAA;AAHF;;AAMA;EACE,oCAAA;AAHF;;AAMA;EACE,aAAA;EACA,SAAA;EACA,kCAAA;EACA,uBAAA;EACA,sBAAA;AAHF","sourcesContent":[".navbarContainer {\n  background-color: light-dark(\n    var(--mantine-color-white),\n    var(--mantine-color-dark-6)\n  );\n  height: 100vh;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  padding: var(--mantine-spacing-md);\n  max-height: 60px;\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));\n}\n\n.logo {\n  display: flex;\n  padding-top: 0.5rem;\n\n  svg {\n    max-width: 80px;\n  }\n}\n\n.links {\n  flex: 1;\n  padding-top: 1rem;\n}\n\n.linksInner {\n  padding: var(--mantine-spacing-xs) 0;\n}\n\n.footer {\n  display: flex;\n  gap: 1rem;\n  padding: var(--mantine-spacing-md);\n  justify-content: center;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarContainer": `sideNav_navbarContainer__dsmKp`,
	"header": `sideNav_header__YrMUD`,
	"logo": `sideNav_logo__m+l8O`,
	"links": `sideNav_links__uT56x`,
	"linksInner": `sideNav_linksInner__bRuse`,
	"footer": `sideNav_footer__1vJbM`
};
export default ___CSS_LOADER_EXPORT___;
