import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { Close } from "../img/Close";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { IAgentCreate } from "../interfaces/Agent";
import { ICityResult } from "../interfaces/ICityResult";
import { default_location, default_voice } from "../utils/consts";
import {
  showFriendlyErrorFromData,
  titleCase,
  useMediaMobile,
  useNavigateToTop,
  useReferer,
  useWindowSize,
} from "../utils/utils";
import { ErrorAlert } from "./alert/ErrorAlert";
import { NucleusButton } from "./NucleusButton";
import { RemoveMemberDialog } from "./dialog/RemoveMemberDialog";
import { ProAlertDialog } from "./dialog/ProAlertDialog";

function formatCity(city: ICityResult): string {
  return city.city + ", " + city.region;
}
function unformatCity(cities: ICityResult[], location: string) {
  const parts = location.split(",", -1);
  const city = parts[0].trim();
  const region = parts[1].trim();
  return cities.find((c) => c.city === city && c.region === region);
}

interface IFormInput {
  email: string;
  cityId: ICityResult;
  businessName: string;
  voice: string;
  agents?: IAgentCreate[];
}
const locationSchema = {
  city: yup.string().required(),
  region: yup.string().required().length(2),
  country: yup.string().required().length(2),
};

const agentSchema = {
  id: yup.string(),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (!isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
};

const schema = yup
  .object({
    cityId: yup.object(locationSchema),
    email: yup
      .string()
      .required("Please enter a valid email address.")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    businessName: yup.string().required("Business name field is required."),
    voice: yup.string().required("Voice field is required."),
    agents: yup
      .array()
      .min(1, "Oops. Please add at least one user for your system.")
      .of(yup.object().shape(agentSchema)),
  })
  .required();

const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    height: '56px',
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .MuiOutlinedInput-input': {
    '&:focus': {
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    '&::selection': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  // Add these to override any focus styles
  '& .MuiInputBase-root.Mui-focused': {
    boxShadow: 'none !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
  },
};

export default function LandingViewForm() {
  const referer = useReferer();
  const theme = useTheme();
  const isMobile = useMediaMobile();
  const {
    data: {authToken},
    actions: { isLoggedIn, createGuest },
  } = useContext(AuthContext);
  const {
    data: { cities: defaultCities, voices },
    actions: { setWarning },
  } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState("");
  const [inputValue, setInputValue] = useState<string>(
    formatCity(default_location())
  );
  const [cities, setCities] = useState<ICityResult[]>(defaultCities);

  const setErrorAndScroll = (err: any) => {
    setError(err);
    setTimeout(() => {
      document.getElementById("errorAlert")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 250);
  };

  const { control, handleSubmit, setValue, getValues, formState, reset } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        email: "",
        cityId: default_location(),
        businessName: "",
        voice: default_voice(),
        agents: [{ id: uuidv4(), first_name: "", last_name: "", phone: "" }],
      },
    });

  useEffect(() => {
    setCities(defaultCities);
  }, [defaultCities]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const cityId = getValues("cityId");
      if (!cityId) {
        const i = cities.findIndex(
          (item) => item.city === default_location().city
        );
        setValue("cityId", i >= 0 ? default_location() : cities[0]);
        setInputValue(formatCity(getValues("cityId")));
      }
    }
  }, [cities, getValues, setValue]);

  useEffect(() => {
    if (voices && voices.length) {
      const voice = getValues("voice");
      if (!voice) setValue("voice", voices[0].name.toLowerCase());
    }
  }, [voices, getValues, setValue]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleAddAgent = () => {
    if (fields.length < 10) {
      append({ id: uuidv4(), first_name: "", last_name: "", phone: "" });
    }
  };

  const [dialogIndex, setDialogIndex] = useState(-1);
  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };
  const navHome = (token: any) => {
    const baseUrl =  process.env.REACT_APP_APP_URL;
    if(baseUrl)
      window.parent.location.href = baseUrl +"/check?token=" + token
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, cityId, businessName, voice, agents } = data;
    if (!agents || agents?.length <= 0) return;
    if (loading) return;

    setError("");
    setSuccess("");
    setLoading(true);
    createGuest(email, cityId, businessName, voice, agents, referer)
      .then((data) => {
        setError(null);
        const msg = showFriendlyErrorFromData(data, "");
        if (msg) setWarning(msg);
        navHome(data?.data?.access_token);
      })
      .catch((error) => {
        setErrorAndScroll(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [canFocus, setCanFocus] = useState(true);
  const onError = () => {
    setCanFocus(true);
  };
  useEffect(() => { 
    if (formState.errors && canFocus) {
      const keyify = (obj: any, prefix = ""): string[] =>
        Object.keys(obj).reduce((res: string[], el: string) => {
          if (typeof obj[el] === "object" && obj[el] !== null) {
            return [...res, ...keyify(obj[el], prefix + el + ".")];
          }
          return [...res, prefix + el];
        }, []);

      // Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
      const errors = formState.errors;
      const keys = keyify(errors);
      const filterKeys = keys
        .filter((key) => key.endsWith(".message"))
        .map((key) => key.slice(0, -1 * ".message".length));

      const elements = filterKeys
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );

      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari.
        errorElement.focus({ preventScroll: true });
        setCanFocus(false); // so the form doesn't suddenly jump to the next input that has error
      }
    }
  }, [formState, canFocus]);

  const onClickRemove = (index: number) => {
    const values = getValues();
    const agents = values.agents || [];
    const agent = agents[index];
    if (agent && !agent.first_name && !agent.last_name && !agent.phone)
      handleRemoveAgent(dialogIndex);
    else setDialogIndex(index);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'background.default',
        padding: { xs: 2, md: 4 },
        backgroundImage: 'url(/images/background.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        overflowY: 'auto',
        position: 'relative',
      }}
    >
       <img 
        src="/icon-512.png" 
        alt="Site icon" 
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          width: '55px',
          height: '55px'
        }}
      />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1000px',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          my: { xs: 4, md: 6 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            color: '#4B5EAA',
            textAlign: 'center',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
            lineHeight: 1.2,
            fontFamily: 'aspecta, sans-serif',
          }}
        >
          Let's get started
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'center',
            maxWidth: '600px',
            color: '#333',
            mx: 'auto',
            px: { xs: 2, md: 0 },
          }}
        >
          Activate your <Box component="span" sx={{ fontWeight: 'bold' }}>free</Box> account and receive your dedicated business phone number and your AI employee. <Box component="span" sx={{ fontWeight: 'bold' }}>No credit card or app download required.</Box> 
        </Typography>

        <Paper
          sx={{
            backgroundColor: '#fff',
            borderRadius: 2,
            width: '100%',
            padding: { xs: '16px', sm: '24px' },
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Stack spacing={3}>
              <ErrorAlert id="errorAlert" error={error} />
              {success && <Alert severity="success">{success}</Alert>}

              <Box sx={{ 
                maxWidth: '1000px',
                margin: '0 auto',
                px: '24px',
                pt: '24px'
              }}>
                {/* Email Field */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Email"
                          fullWidth
                          error={!!formState.errors.email}
                          helperText={formState.errors.email?.message?.toString()}
                          placeholder="Enter your email address"
                          sx={textFieldStyles}
                        />
                      )}
                    />
                  </Grid>

                  {/* City and Voice Selection Row */}
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="cityId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={cities.map(item => formatCity(item))}
                          value={formatCity(field.value)}
                          onChange={(_, newValue) => {
                            field.onChange(newValue ? unformatCity(cities, newValue) : default_location())
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Phone Region"
                              error={!!formState.errors.cityId}
                              helperText={formState.errors.cityId?.message?.toString()}
                              fullWidth
                              sx={textFieldStyles}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="voice"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!formState.errors.voice}>
                          <TextField
                            {...field}
                            select
                            label="Attendant Voice"
                            error={!!formState.errors.voice}
                            helperText={formState.errors.voice?.message?.toString()}
                            fullWidth
                            sx={textFieldStyles}
                          >
                            {voices?.filter(v => !v.is_premium).map(v => (
                              <MenuItem key={v.name.toLowerCase()} value={v.name.toLowerCase()}>
                                {titleCase(v.name)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {/* Business Name Field */}
                  <Grid item xs={12}>
                    <Controller
                      name="businessName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Business Name"
                          fullWidth
                          error={!!formState.errors.businessName}
                          helperText={formState.errors.businessName?.message?.toString()}
                          placeholder="Enter your business name"
                          sx={textFieldStyles}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {/* Agents Section */}
                <Box sx={{ mt: 3 }}>
                  {fields.map((agent, index) => (
                    <Grid
                      key={agent.id}
                      container
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={12} md={4}>
                        <Controller
                          control={control}
                          name={`agents.${index}.first_name`}
                          render={({ field }) => {
                            const e =
                              formState.errors?.agents?.at !== undefined &&
                              formState.errors?.agents.at(index) !== undefined &&
                              formState.errors?.agents?.at(index)?.first_name?.message;
                            return (
                              <TextField
                                {...field}
                                label="First Name"
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                fullWidth
                                placeholder="Enter first name"
                                sx={textFieldStyles}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Controller
                          control={control}
                          name={`agents.${index}.last_name`}
                          render={({ field }) => {
                            const e =
                              formState.errors?.agents?.at !== undefined &&
                              formState.errors?.agents.at(index) !== undefined &&
                              formState.errors?.agents?.at(index)?.last_name?.message;
                            return (
                              <TextField
                                {...field}
                                label="Last Name"
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                fullWidth
                                placeholder="Enter last name"
                                sx={textFieldStyles}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={fields.length > 1 ? 3 : 4}>
                        <Controller
                          control={control}
                          name={`agents.${index}.phone`}
                          render={({ field }) => {
                            const e =
                              formState.errors?.agents?.at !== undefined &&
                              formState.errors?.agents.at(index) !== undefined &&
                              formState.errors?.agents?.at(index)?.phone?.message;
                            return (
                              <TextField
                                {...field}
                                label="Phone"
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                fullWidth
                                placeholder="Enter phone number"
                                sx={textFieldStyles}
                              />
                            );
                          }}
                        />
                      </Grid>

                      {fields.length > 1 && (
                        <Grid item xs={12} md={1}>
                          <RemoveMemberDialog
                            open={dialogIndex >= 0}
                            onClose={() => setDialogIndex(-1)}
                            onCancel={() => setDialogIndex(-1)}
                            onOk={() => {
                              setDialogIndex(-1);
                              handleRemoveAgent(dialogIndex);
                            }}
                          />

                          {!isMobile ? (
                            <IconButton
                              disabled={fields.length <= 1}
                              onClick={() => onClickRemove(index)}
                            >
                              <Close />
                            </IconButton>
                          ) : (
                            <Button
                              sx={{
                                width: "100%",
                                color: theme.palette.error.dark,
                                marginBottom: "32px",
                              }}
                              variant="outlined"
                              onClick={() => onClickRemove(index)}
                            >
                              Remove team member
                            </Button>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Box>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  <NucleusButton
                    variant="contained"
                    type={isLoggedIn() ? "button" : "submit"}
                    disabled={loading}
                    sx={{
                      minWidth: { xs: '100%', md: '200px' },
                      height: '48px',
                      borderRadius: '20px',
                      backgroundColor: '#4CAF50',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#2a2928',
                      },
                    }}
                  >
                    {isLoggedIn() ? "Go to Dashboard" : "Create my account"}
                    {loading ? (
                      <LoadingCircle />
                    ) : (
                      <Box component="span" sx={{ ml: 1 }}>
                        <RightArrow />
                      </Box>
                    )}
                  </NucleusButton>
                </Box>
              </Box>
            </Stack>
          </form>
        </Paper>
      </Box>
      <Box
        component="footer"
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '0',
          right: '0',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '20px',
          backgroundColor: 'transparent',
          padding: '10px',
          borderRadius: '4px',
          margin: '0 10px',
          width: 'auto',
          fontSize: window.innerWidth < 600 ? '12px' : '14px',
        }}
      >
        <a
          href={process.env.REACT_APP_WEB_URL + "/terms"}
          style={{
            color: '#000',
            textDecoration: 'none',
            fontSize: 'inherit',
            display: 'inline-block'
          }}
        >
          Terms
        </a>
        <a
          href={process.env.REACT_APP_WEB_URL + "/privacy"}
          style={{
            color: '#000',
            textDecoration: 'none',
            fontSize: 'inherit',
            display: 'inline-block'
          }}
        >
          Privacy
        </a>
        <a
          href={process.env.REACT_APP_WEB_URL + "/faq"}
          style={{
            color: '#000',
            textDecoration: 'none',
            fontSize: 'inherit',
            display: 'inline-block'
          }}
        >
          FAQ
        </a>
        <span style={{ 
          color: '#000',
          fontSize: 'inherit',
          position: 'absolute',
          right: '10px'
        }}>
          © 2024 Nucleus Technologies Inc.
        </span>
      </Box>
    </Box>
  );
}
