import { z } from 'zod';
import { Text, Anchor } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useDebouncedValue } from '@mantine/hooks';
import { useContext, useState, useCallback, useEffect } from 'react';

import routes from 'utils/routes';
import Form from 'components/form';
import { getNames } from 'utils/string';
import useReferer from 'hooks/useReferer';
import AuthContext from 'context/AuthContext';
import Testimonial from 'components/Testimonial';
import useFormHandler from 'hooks/useFormHandler';
import { schemaFields } from 'utils/schemaBuilder';
import BusinessContext from 'context/BusinessContext';
import TermsAndPolicy from 'components/TermsAndPolicy';
import { removeProtocol, addProtocol, isValidUrl } from 'utils/url';

const Onboarding = () => {
  const referer = useReferer();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [websiteLoading, setWebsiteLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [websiteFromPlaces, setWebsiteFromPlaces] = useState<string>('');
  const [businessOptions, setBusinessOptions] = useState<Array<any>>([]);

  const {
    actions: { getBusinesses, getPlaceDetails, validateWebsite },
  } = useContext(BusinessContext);
  const {
    actions: { signupWithWebsite },
    data: { registerEmail, registerPassword },
  } = useContext(AuthContext);
  const [debouncedSearch] = useDebouncedValue(search, 200);
  const [websiteValue, setWebsiteValue] = useState('');
  const [debouncedWebsite] = useDebouncedValue(websiteValue, 500);

  const formSchema = z.object({
    protocol: z.string().optional(),
    business_name: z.string().min(1, 'Please enter or select a company name'),
    formatted_website: z
      .string()
      .refine((val) => val === '' || isValidUrl(val), {
        message: 'Please enter a valid website (e.g. example.com)',
      })
      .optional()
      .or(z.literal('')),
    website: schemaFields.url.schema.optional().or(z.literal('')),
    phone: schemaFields.phoneNumber.schema,
    full_name: schemaFields.fullName.schema,
    location: z.object({
      city: z.string(),
      region: z.string(),
      country: z.string(),
      latitude: z.number(),
      longitude: z.number(),
    }),
  });

  const {
    form,
    error,
    setError,
    loading: formLoading,
  } = useFormHandler(formSchema, {
    initialValues: {
      website: '',
      full_name: '',
      business_name: '',
      protocol: 'https://',
      formatted_website: '',
      phone: '',
      location: {
        city: '',
        region: '',
        country: '',
        latitude: 0,
        longitude: 0,
      },
    },
  });

  useEffect(() => {
    const searchBusinesses = async () => {
      if (debouncedSearch.length < 2) {
        setBusinessOptions([]);
        setLoading(false);
        return;
      }
      setLoading(true);

      try {
        const results = await getBusinesses(debouncedSearch);
        setBusinessOptions(results);
      } catch (error) {
        console.error('Error searching businesses:', error);
        setBusinessOptions([]);
      }
      setLoading(false);
    };

    searchBusinesses();
  }, [debouncedSearch, getBusinesses]);

  useEffect(() => {
    const validateWebsiteUrl = async () => {
      if (!debouncedWebsite) {
        setWebsiteLoading(false);
        return;
      }

      const errorMessage = 'Please enter an active website';

      try {
        const isValid = await validateWebsite(debouncedWebsite);
        if (!isValid) {
          form.setErrors({
            website: errorMessage,
            formatted_website: errorMessage,
          });
        }
      } catch (error: any) {
        if (error.status === 400) {
          form.setErrors({
            website: errorMessage,
            formatted_website: errorMessage,
          });
        }
      } finally {
        setWebsiteLoading(false);
      }
    };

    validateWebsiteUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedWebsite]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { first_name, last_name } = getNames(values.full_name);
    const { formatted_website, protocol, full_name, ...rest } = values;

    try {
      await signupWithWebsite({
        ...rest,
        referer,
        last_name,
        first_name,
        email: registerEmail || '',
        password: registerPassword || '',
      });
      navigate(routes.welcomePath.href);
    } catch (error) {
      setError(
        'An error occurred while creating your company. Please try again.'
      );
    }
  };

  const handleBusinessSearch = useCallback(async (query: string) => {
    setSearch(query);
  }, []);

  const handleWebsiteChange = (value: string) => {
    setWebsiteLoading(true);
    setWebsiteValue(value);
    const { protocol, url } = removeProtocol(value);

    form.setValues({
      website: value,
      formatted_website: url,
      protocol: protocol || form.values.protocol,
    });

    setWebsiteFromPlaces('');
  };

  const handleBusinessSelect = async (value: string) => {
    if (value === search) {
      form.setValues({
        business_name: value,
      });
      return;
    }

    const place = await getPlaceDetails(value);

    if (place) {
      const formValues: Partial<z.infer<typeof formSchema>> = {
        business_name: place.displayName?.text,
        location: place.location,
      };

      if (!form.values.website) {
        handleWebsiteChange(place.websiteUri);
      } else {
        setWebsiteFromPlaces(place.websiteUri || '');
      }
      form.setValues(formValues);
    }
  };

  const getOptions = () => {
    const options = businessOptions.map((option) => ({
      value: option.id,
      label: option.displayName.text,
      description: option.formattedAddress,
    }));

    if (search.length > 1) {
      const defaultOption = {
        value: search,
        label: search,
        description: 'Select to use this name',
      };

      return [defaultOption, ...options];
    }

    return options;
  };

  const renderOptionForBusinesses = ({ option }: { option: any }) => {
    const { value, label, description } = option;
    const isCustomOption = value === label;

    return (
      <div>
        <Text size="sm" fw={isCustomOption ? 500 : undefined}>
          {isCustomOption ? `Use "${label}"` : label}
        </Text>
        <Text size="xs" c="dimmed">
          {description}
        </Text>
      </div>
    );
  };

  return (
    <div className="flex-1 grid grid-cols-1 md:grid-cols-2 min-h-screen overflow-hidden">
      <div className="min-h-screen  overflow-y-auto md:overflow-y-hidden">
        <nav className="h-[70px] sticky top-0 w-full custom-bg-color  px-3 md:px-6 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <img src="/images/logo-icon.svg" alt="Nucleus Logo" />
          </div>

          <span></span>
        </nav>
        <div className="md:flex items-center lg:items-center signup-container extend px-3 md:px-0">
          <div className="border custom-border-color bg-white rounded-2xl p-6 md:p-12 md:w-5/6 xl:w-[540px] mx-auto">
            <div className="mb-4 text-center">
              <h2 className="text-2xl font-semibold custom-text-color">
                Let's train your new AI employee!
              </h2>
            </div>

            <Form form={form} error={error} onSubmit={onSubmit}>
              <Form.Autocomplete
                loading={loading}
                name="business_name"
                label="Business name"
                data={getOptions()}
                onChange={handleBusinessSearch}
                onOptionSubmit={handleBusinessSelect}
                renderOption={renderOptionForBusinesses}
                placeholder="Search or type your company name"
                error={
                  search.length > 0 && !form.values.business_name
                    ? 'Please enter or select a company name'
                    : undefined
                }
              />
              <div>
                <Form.UrlInput
                  placeholder="URL"
                  name="formatted_website"
                  loading={websiteLoading}
                  label="Website URL (Optional)"
                  prefix={form.values.protocol}
                  onChange={(e) => {
                    setWebsiteLoading(true);
                    setWebsiteValue(e.target.value);
                    const { url, protocol } = removeProtocol(e.target.value);
                    const website = addProtocol(
                      url,
                      protocol || form.values.protocol
                    );

                    form.setValues({
                      formatted_website: url,
                      protocol: protocol || form.values.protocol,
                      website,
                    });
                    form.getInputProps('formatted_website').onChange(url);
                  }}
                  onBlur={() => {
                    // do nothing
                  }}
                />
                {websiteFromPlaces &&
                  websiteFromPlaces !== form.values.website && (
                    <Text
                      style={{
                        margin: '-1rem 0 1.25rem',
                        fontSize: 'calc(0.75rem * var(--mantine-scale))',
                      }}
                    >
                      <Anchor
                        style={{
                          color: 'var(--mantine-color-dimmed)',
                          borderColor: 'var(--mantine-color-dimmed)',
                          fontSize: 'calc(0.75rem * var(--mantine-scale))',
                        }}
                        onClick={() => handleWebsiteChange(websiteFromPlaces)}
                      >
                        or use {websiteFromPlaces}?
                      </Anchor>
                    </Text>
                  )}
              </div>
              <Form.Input
                type="text"
                name="full_name"
                label="Your full name"
                placeholder="Ex. Michael Douglas"
              />
              <Form.PhoneInput
                name="phone"
                label="Your mobile number"
                placeholder="Enter your mobile number"
              />
              <Form.Submit
                loading={formLoading}
                disabled={formLoading || websiteLoading}
              >
                Continue
              </Form.Submit>
            </Form>
          </div>
        </div>
        <div className="w-full md:h-[50px]">
          <TermsAndPolicy />
        </div>
      </div>
      <div className="min-h-screen hidden md:block bg-white border-l custom-border-color">
        <nav className="h-[70px] sticky bg-white z-10 top-0 w-full  px-6 flex items-center justify-between">
          <span></span>
        </nav>
        <div className="min-h-[calc(100vh-70px)] flex flex-col justify-end">
          <Testimonial />
          <div className="w-full mx-auto relative min-h-[500px] ml-[10%] xl:min-h-[600px] overflow-hidden border-l-[3px] border-t-[3px] border-[#5e6ab2] rounded-ss-3xl">
            <img
              src="/images/report-scrnshot.png"
              alt=""
              className="absolute bottom-0 top-0 custom-box-shadow w-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
