import { useEffect, useState } from "react";
import LandingViewForm from "../components/LandingViewForm";
import { Box } from '@mui/material';

export default function RegistrationEmbed() {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const elem = document.getElementById("landing-view-form");
      if (elem)
        if (elem.clientHeight) {
          const newHeight = elem.clientHeight;
          if (height !== newHeight) {
            setHeight(newHeight);
            window.parent.postMessage({ height: elem.clientHeight }, "*");
          }
        }
    }, 100); // updates ten times per second
    return () => clearInterval(intervalId); //This is important
  }, [height]);

  return (
    <Box
      id="landing-view-form"
    >
      <LandingViewForm />
    </Box>
  );
}
