// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaffInfo_card__meE5H {
  background-color: var(--mantine-color-body);
}

.StaffInfo_item__gNmnx {
  padding: var(--mantine-spacing-sm) 0;
}
.StaffInfo_item__gNmnx.StaffInfo_itemActive__szzsF {
  background-color: var(--mantine-primary-color-light);
}

.StaffInfo_switch__ruaEO * {
  cursor: pointer;
}

.StaffInfo_title__PT5vA {
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/attendant/staffDirectory/StaffInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;AACF;;AAEA;EACE,oCAAA;AACF;AACE;EACE,oDAAA;AACJ;;AAIE;EACE,eAAA;AADJ;;AAKA;EACE,cAAA;AAFF","sourcesContent":[".card {\n  background-color: var(--mantine-color-body);\n}\n\n.item {\n  padding: var(--mantine-spacing-sm) 0;\n\n  &.itemActive {\n    background-color: var(--mantine-primary-color-light);\n  }\n}\n\n.switch {\n  & * {\n    cursor: pointer;\n  }\n}\n\n.title {\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `StaffInfo_card__meE5H`,
	"item": `StaffInfo_item__gNmnx`,
	"itemActive": `StaffInfo_itemActive__szzsF`,
	"switch": `StaffInfo_switch__ruaEO`,
	"title": `StaffInfo_title__PT5vA`
};
export default ___CSS_LOADER_EXPORT___;
