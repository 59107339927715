export interface AvailabilityTime {
  start?: string;
  end?: string;
  enabled: boolean;
}

export enum DaysOfWeek {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATUDAY = "saturday",
  SUNDAY = "sunday",
}

export enum Status {
  AVAILABLE = "available",
  SCHEDULE = "schedule",
  AWAY = "away",
}

export interface Availability {
  monday?: AvailabilityTime;
  tuesday?: AvailabilityTime;
  wednesday?: AvailabilityTime;
  thursday?: AvailabilityTime;
  friday?: AvailabilityTime;
  saturday?: AvailabilityTime;
  sunday?: AvailabilityTime;
}

export enum CommunicationType {
  TEXT = "text",
  EMAIL = "email",
  CALL = "call",
}

export interface TeamMember {
  id?: string;
  first_name: string;
  last_name: string;
  phone: string;
  email?: string;
  status?: Status;
  availability: Availability;
  prefered_communication: CommunicationType;
  tags: string[];
}

export interface IAgentCreate {
  first_name: string;
  last_name: string;
  phone: string;
}
